import * as React from "react"
import { graphql } from "gatsby"

import { getImage } from "gatsby-plugin-image"
import { BgImage } from 'gbimage-bridge';

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Container } from 'react-bootstrap'

import thomas from "../images/thomas.jpg"

const SecondPage = ({data}) => (
  <Layout>
    <Seo title="About" />
    <h1>Meet Thomas Tripolt of All Electric</h1>
    <p>Master electrician and craftsman Thomas Tripolt of All Electric has served the Marin community since 1984. Both homeowners and business owners have come to rely on his prompt, reliable service and high quality work. Rather than working with a member of the All Electric team, you will always see Thomas at your doorstep. Thomas’s attention to detail and depth of experience ensures that you will always receive the highest quality results.</p>
    <p className="text-center">
      <img src={thomas} alt="Thomas portrait" className="img-fluid" />
    </p>
    <p>Thomas Tripolt, the founder of All Electric, was born in Austria, where he received his electrician training. Taught in the European tradition of old world craftsmanship, Thomas learned how to perform every job, both large and small, with the highest standard of quality and attention to detail. Thomas subsequently moved to Germany to pursue a career in electronics.</p>
    <p>In 1984, Thomas came to California for a visit, and he fell in love with the beauty of the state and the friendliness of its people. Shortly thereafter, he moved to Marin County, where he established All Electric to serve the electrical needs of homeowners and commercial businesses.</p>
    {/* <GatsbyImage image={getImage(data.light)} alt="The Desert" /> */}
    <BgImage image={getImage(data.light)} className="subhead mb-3">
      <div className="color-overlay d-flex pt-4 justify-content-center align-items-center">
          <div className="content-box">
            <Container>
              <p className="lead text-center px-xs-0 px-sm-3"><strong>Thomas Tripolt built his electrician business by providing customers
with prompt, reliable service and high quality work.</strong></p>
              {/* <Link to="/services/" className="btn btn-outline-light">Learn More</Link> */}
            </Container>
          </div>
          </div>
          </BgImage>
    <p>Rather than send a member of his crew, Thomas performs all of the work himself. The vast majority of Thomas’ projects today come through referrals by current or past customers. Many of his clients are repeat customers.</p>
    <p>When you choose to work with Thomas, you’ll benefit from the knowledge, skills and excellence of a master craftsman. He serves clients throughout Marin County, including: San Rafael, Novato, Kentfield, Larkspur, Sausalito, Tiburon, San Anselmo and Fairfax.</p>
    <p>Thomas has lived in Kentfield since 1984. When he is not helping his customers, he can be found hiking the hills of Marin County and dancing – salsa and freestyle.</p>
    <p className="text-center">Cal. License: C10-525460 <br />Bonded and Insured</p>
  </Layout>
)

export default SecondPage

export const query = graphql`
  query { 
    light: file(relativePath: {eq: "edison-bulbs-hanging.jpg"}) {
      relativePath
      base
      childImageSharp {
        gatsbyImageData(
          webpOptions: {quality: 50}
          blurredOptions: {width: 50, toFormat: WEBP}
          width: 2000
          formats: AUTO
          jpgOptions: {progressive: false, quality: 50}
        )
      }
    }
  }
`


// export const pageQuery = graphql`
//   query {
//     backgroundLight: file(relativePath: {eq: "background-light.jpg"}) {
//       childrenImageSharp {
//         gatsbyImageData(
//           quality: 50
//           width: 1000
//           blurredOptions: {width: 50}
//           placeholder: BLURRED
//         )
//       }
//     }
//   }
// `